import './Footer.scss'


export default function Footer() {


    return (
        <div className='footer container-fluid'>
            <p>Ezer Daru Adománybolt &copy; 2025</p>
        </div>
    )
}