import './About.scss'


const About = () => {

  return (
    <div className='about-box' id='about'>
      <h2>Rólunk</h2>
      <p>
        Az Ezer Daru Adománybolt a Pestszentimrei Nagycsaládos Egyesület által működtetett adománybolt tapasztalataira alapozva 2016-ban alakult.<br />
        Első üzletünket Kispesten nyitottuk, többszöri költözés után a Wekerlén, Kós Károly téren találtunk otthonra.
        Pestszentimrén 2023. novemberében nyitottuk meg második adományboltunkat.<br />
      </p>
      <p>
        Boltunk nonprofit módon működik, vagyis áraink úgy vannak meghatározva, hogy költségeinket fedezzék, ezért haszon nem képződik. Ugyanakkor segítjük a hajléktalan ellátást, utcai szolgálatot, támogatunk anyaotthonokat, vidéki óvodákat, állatmenhelyeket és mindenkit, akinek segíteni tudunk.
        Támogatást nem kapunk, boltunknak önállóan kell fenntartania magát. Fizetnünk kell bérleti díjat, rezsi költségeket, munkatársaink után a járulékokat, adót, és egyéb költségeinket.
      </p>
      <p>
        Adományboltunkat sokan szeretik, népszerű nem csak a helybéliek körében, a környező településekről is sokan látogatnak minket. Vásárlóink között vannak, akik környezetvédelmi szempontokat tartják szemelőtt, sokan a kedvező árakért jönnek hozzánk.
      </p>
      <p className='text-center'>
        Szeretettel várunk minden vásárlót és ezúton is köszönünk minden jó szívű adományt!
      </p>
    </div>
  )
}

export default About
